<template>
  <users-table :headers="headers" :users="users" @triggerPageChange="readPaginatedUsers($event)" 
  @deleteUser="deleteUser($event)"/>
</template>

<script>
import UsersTable from "../../components/Tables/UsersTable.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Users",
        align: "start",
        sortable: false,
        value: "name",
        width: "200px",
      },
      { text: "Last Login At", value: "last_login_at", width: "200px" },
      { text: "Status", value: "status", width: "200px" },
      { text: "Roles", value: "role", width: "200px" },
      { text: 'Access', value: 'access' },
      { value: "actions", width: "130px", align: "end" },
      
    ],
  }),
  async mounted() {
    //Users
    await this.fetchUsers();
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  components: {
    UsersTable,
  },
  methods:{
    async fetchUsers() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readUsers");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    async readPaginatedUsers(value) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readPaginatedUsers", value);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async deleteUser(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("deleteUser", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "User deleted successfully!",
          group: "success",
        });
        
        await this.fetchUsers();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  }
};
</script>