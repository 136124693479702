<template>
  <section>
    <v-card id="users" v-if="users">
      <ConfirmDelete :dialog="dialog" :item="user" @delete="rundeleteFn($event)" @dismiss="dismissFn($event)" />
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col cols="3">
            <div class="d-flex align-center">
              <v-btn v-if="superAdmin" class="mr-2" depressed color="primary" to="/users-management/add-user">
                <v-icon left>mdi-plus</v-icon> Add User</v-btn>

              <v-btn depressed color="secondary" @click="openInviteUserModal">
                <v-icon left>mdi-message</v-icon> Invite User</v-btn>
            </div>
          </v-col>
          <v-col cols="9">
            <div class="d-flex justify-end">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined
                dense class="shrink"></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table :headers="headers" :items="filtered_users" :search="search" hide-default-footer
        :server-items-length="pagination.totalItems" :options.sync="pagination" dense>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link :to="{
            name: '',
            params: {
              id: item?.id,
            },
          }">
            <div class="d-flex align-center">
              <v-avatar color="warning" class="mr-3" size="38">
                <span class="white--text">{{ item.name.charAt(0).toUpperCase() }}</span>
              </v-avatar>
              <div>
                <h5 class="mt-4">{{ item.name }}</h5>
                <p>{{ item.email }}</p>
              </div>
            </div>
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.clients="{ item }">
          <!-- <v-btn small v-if="!item.role" color="#e75087" dark>Assign Role</v-btn> -->
          <span v-for="client in item.clients" :key="client?.id">
            <li>{{ client?.client_name }}</li>
          </span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.fb_ad_accounts="{ item }">
          <!-- <v-btn small v-if="!item.role" color="#e75087" dark>Assign Role</v-btn> -->
          <span v-for="fb_ad_account in item.fb_ad_accounts" :key="fb_ad_account?.id">
            <li>{{ fb_ad_account.name }}</li>
          </span>

        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.access="{ item }">

          <div>
            <v-menu bottom :offset-y="true" v-model="item.menuVisible" :close-on-content-click="false" :nudge-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="secondary" @click="showAccessLevel(item)" depressed dark v-bind="attrs" v-on="on">
                  Access Level
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            DSP Access
                          </th>
                          <th class="text-left">
                            FB Access
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span v-for="client in item.clients" :key="client?.id">
                              <li>{{ client?.client_name }}</li>
                            </span>
                          </td>
                          <td>
                            <span v-for="fb_ad_account in item.fb_ad_accounts" :key="fb_ad_account?.id">
                              <li>{{ fb_ad_account.name }}</li>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.role="{ item }">
          <!-- <v-btn small v-if="!item.role" color="#e75087" dark>Assign Role</v-btn> -->
          <span v-for="role in item.roles" :key="role?.id">
            <li>{{ role.name }}</li>
          </span>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_login_at="{ item }">
          <span>
            {{ item.last_login_at ? new Date(item.last_login_at).toLocaleString('en-US', {
              year: "numeric", month: "long",
              day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit'
            }) : '-' }}
          </span>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="`${item.status === 'active' ? 'success lighten-5' : 'red'}`"
            :text-color="`${item.status === 'active' ? 'success' : 'white'}`"
            class="font-weight-bold"
            small
          >
            <v-icon x-small left>mdi-circle</v-icon> {{ item.status }}
          </v-chip>
        </template>


        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-btn small class="mr-5" color="blue" icon @click="editUser(item?.id)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn small color="red" icon @click="openModal(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between align-center pa-4" v-if="users">
        <v-btn outlined class="font-transform-inherit" color="#C6C5C5" small>
          <span class="dashboard-blue">Showing page {{ pagination.current }}</span>
        </v-btn>
        <v-pagination v-model="pagination.page" :length="pagination.totalItems" circle prev-icon="mdi-arrow-left"
          next-icon="mdi-arrow-right" color="#3371E2" class="custom" @input="handlePageChange"></v-pagination>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isInviteUserModalOpen" max-width="600">
      <v-card :loading="isLoading">
        <v-card-title class="text-h6">
          Invite user 🚀
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-5">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="flex-column">
              <v-col>
                <v-text-field dense v-model="email" :error="errors?.email ? true : false" :error-messages="errors?.email"
                  hide-details="auto" :rules="[rules.email]" outlined depressed small label="Email address"
                  type="email"></v-text-field>
              </v-col>

              <v-col v-if="superAdmin">
                <v-select :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" hide-details="auto" outlined
                  dense return-object label="Select Agency" :items="agencies" item-text="agency_name" item-value="id"
                  v-model="selected_agency"></v-select>
              </v-col>

              <v-col v-if="superAdmin">
                <v-combobox :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" hide-details="auto" outlined
                  dense return-object label="Choose Roles" :items="roles" item-text="name" item-value="id" multiple
                  deletable-chips small-chips clearable chips v-model="selected_roles"></v-combobox>
              </v-col>

              <v-col>
                <v-combobox :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" hide-details="auto" outlined
                  dense return-object label="Choose permissions" :items="permissions" item-text="name" item-value="id"
                  multiple deletable-chips small-chips clearable chips v-model="selected_permissions"></v-combobox>
              </v-col>

              <v-col>
                <v-btn depressed :loading="isLoading" color="primary" @click="sendUserInvitation"
                  :disabled="!valid || isLoading">
                  <v-icon left>mdi-send</v-icon> Send Invitation</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import ConfirmDelete from "../ConfirmDelete.vue";
export default {
  components: {
    ConfirmDelete,
  },
  data: () => ({
    errors: [],
    search: "",
    valid: true,
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
    isInviteUserModalOpen: false,
    selected_permissions: [],
    selected_roles: [],
    selected_agency: null,
    filtered_users: [],
    dialog: false,
    isLoading: false,
    user: {},
    menu: false,
    form: false,
    email: "",
    rules: {
      email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
      length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
      password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
        'Password must contain an upper case letter, a numeric character, and a special character',
      required: v => !!v || 'This field is required',
    },
  }),
  props: {
    headers: Array,
    users: Object,
  },
  watch: {
    users() {
      this.filtered_users = this.users.data;
      this.pagination.page = this.users.current_page;
      this.pagination.totalItems = this.users.last_page;
    },
  },
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
    roles() {
      return this.$store.state.roles;
    },
    agencies() {
      return this.$store.state.all_agencies;
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  methods: {

    showAccessLevel(item) {
      item.menuVisible = false;
    },

    //send user inviation
    async sendUserInvitation() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      //return ids of selected permissions and roles and agency
      const data = {
        email: this.email,
        agency_id: this.selected_agency?.id,
        permissions: this.selected_permissions?.map(item => item.id),
        roles: this.selected_roles?.map(item => item.id),
      };

      this.isLoading = true;

      try {

        await this.$store.dispatch("sendUserInvitation", data);
        this.isInviteUserModalOpen = false;


        this.$notify({
          title: "Invitation sent successfully",
          group: "success",
        });

        //reset form
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {

        let showE = error?.errors

        this.errors = showE

        this.$notify({
          title: error.message,
          group: "errors",
        });

      } finally {
        this.isLoading = false;
      }
    },

    //open invite user modal
    openInviteUserModal() {
      this.isInviteUserModalOpen = true;
      //fetch agencies, permissions and roles
      this.getAgencies();
      this.getPermissions();
      this.getRoles();
    },

    handlePageChange(value) {
      this.pagination.page = value;
      this.$emit("triggerPageChange", value);
    },
    rundeleteFn(id) {
      // Delete user
      this.$emit("deleteUser", id);
    },
    dismissFn() {
      this.dialog = false;
    },
    async openModal(item) {
      this.dialog = !this.dialog;
      // this.modalOpen = true;
      this.user = await item;
    },
    editUser(id) {
      this.$router.push({ name: "update-user", params: { id: id } });
    },

    //Get Permissions
    async getPermissions() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("readPermissions");
      } catch (error) {
        this.error = error

      } finally {
        this.isLoading = false;
      }
    },
    //Get Roles
    async getRoles() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("readRoles");
      } catch (error) {
        this.error = error

      } finally {
        this.isLoading = false;
      }
    },
    //Get Agencies
    async getAgencies() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("readAllAgencies", 100);
      } catch (error) {
        this.error = error

      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
#targeting {
  margin: 2.5rem 0;
}
</style>